:root {
  --orange         : #FC860B;
  --green          : #9DA62B;
  --primary-black  : #4A4A47;
  --ToysGreen      : #008D55;
  --ToysGreenLight : #08D382;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');
@import "reactModal";
@import "sweetAlert";
body {
  font-family : 'Quicksand', sans-serif !important;
}

a {
  color           : var(--ToysGreen) !important;
  text-decoration : none !important;

}

footer {
  margin-bottom : 20px;

  ul {
    margin  : 0;
    padding : 0;

    li {
      display     : inline-block;
      list-style  : none;
      margin      : 0 10px;
      font-size   : 12px;
      line-height : 30px;
    }
  }


}

main {
  @media only screen and (max-width : 480px) {
    margin-bottom : 70px;
  }

  .slider {
    //background : #DEAB2E;
    ////background: -moz-linear-gradient(top, #deab2e9 0%, #f8de53 100%);
    //background : -webkit-linear-gradient(top, #DEAB2E 0%, #F8DE53 100%);
    //background : linear-gradient(to bottom, #DEAB2E 0%, #F8DE53 100%);
    //filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr='#deab2e', endColorstr='#f8de53', GradientType=0);

    background : #F12711; /* fallback for old browsers */
    background : -webkit-linear-gradient(to right, #F12711, #F5AF19); /* Chrome 10-25, Safari 5.1-6 */
    background : linear-gradient(to right, #F12711, #F5AF19); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding    : 70px 20px 0 20px;


    h1 {
      text-transform : uppercase;
      font-family    : 'Montserrat', sans-serif;
      font-style     : normal;
      font-weight    : 700;
      line-height    : 1;
      font-size      : 3rem;
      margin-top     : 0;
      margin-bottom  : 0.5rem;
      color          : #FFFFFF;
    }

    p {
      color : #FFFFFF;
    }

    .slider-image {
      img {
        max-height : 354px;
        float      : right;
      }
    }
  }
}

.nav {
  margin : 10px 0;

  li {
    a {
      color : #4A4A47;
    }
  }
}

.logo {
  margin-bottom : 0;
  text-align    : center;

  img {
    max-width : 230px;
    margin    : 10px 0 10px 0;
  }
}

.cta {
  text-align  : left;
  line-height : 65px;
  @media only screen and (max-width : 768px) {
    font-size : 12px;
  }
  @media only screen and (max-width : 480px) {
    text-align  : center;
    line-height : 27px;
    font-size   : 14px;
    font-weight : 500;
  }

  a {
    color           : var(--primary-black);
    text-decoration : none;
  }
}

a:hover {
  color : var(--ToysGreen) !important;
}

.top-bar {
  height      : 25px;
  line-height : 37px;
  text-align  : right;
  font-size   : 1.2rem;
  font-weight : 500;
}

.no-padding {
  padding-left  : 0 !important;
  padding-right : 0 !important;
}

.toolBox {
  margin-top : -68px;

  .toyLine {

    background-color : #BAC541;
    padding          : 5px 10px;

    //label {
    //  line-height : 40px;
    //  flex        : 50%;
    //}
    //
    //select {
    //  flex : 50%;
    //}
  }
}

.actionSection {
  background-color : #BAC541;
  padding          : 10px 0;

  h2 {
    font-weight : 400;
    color       : #111111;
    font-size   : 18px;
    margin      : 10px 0 5px 0;
  }
}

.SliderComponent {
  //background-color : var(--orange);
  max-height : 400px;
  @media only screen and (max-width : 480px) {
    max-height : 585px;
  }
  @media only screen and (min-width : 481px) and (max-width : 768px) {
    max-height : 430px;
  }
  @media only screen and (min-width : 768px) and (max-width : 820px) {
    max-height : 470px;
  }
  background : rgb(157, 166, 43);
  background : -moz-linear-gradient(30deg, var(--ToysGreenLight) 0%, var(--ToysGreen) 54%);
  background : -webkit-linear-gradient(30deg, var(--ToysGreenLight) 0%, var(--ToysGreen) 54%);
  background : linear-gradient(30deg, var(--ToysGreenLight) 0%, var(--ToysGreen) 54%);
  filter     : progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--ToysGreenLight), endColorstr= var(--ToysGreen), GradientType=1);
  overflow   : hidden;

  h1 {
    //font-family : 'Montserrat', sans-serif;
    color       : #FFFFFF;
    font-weight : 700;
    text-shadow : 0 1px 1px #000000;
    @media only screen and (max-width : 480px) {
      font-size : 20px;
    }
  }

  p {
    font-family : 'Montserrat', sans-serif;
    color       : #FFFFFF;
    font-weight : 300;
    @media only screen and (max-width : 480px) {
      font-size   : 13px;
      line-height : 16px;
      //text-shadow : 0 1px 15px #000000;
    }
  }

  .introduction {
    padding     : 40px 10px 30px 10px;
    line-height : 22px;
    text-align  : center;

    @media only screen and (max-width : 480px) {
      padding : 17px 0 8px 0;

    }
  }

  .showOnMobileBrandCover {

    @media only screen and (max-width : 480px) {
      height              : 175px;
      background-position : center right !important;

    }

    .banner-image {
      display    : none;
      height     : auto;
      max-height : 180px;
      width      : auto;
      object-fit : contain;
      margin     : auto;
      @media screen and (max-width : 768px) {
        display : block;
      }
    }


  }

  p > .upcImage {
    float     : left;
    position  : relative;
    max-width : 100px;
    margin    : 1rem 1rem 4rem 1rem;
    padding   : 0;

    display   : none;
    @media screen and (max-width : 768px) {
      display : block;
    }
  }

  .banner {
    display         : flex;
    //align-items     : flex-end;
    padding         : 0 0 30px 0;
    justify-content : flex-end;
    flex-direction  : column;
    @media only screen and (max-width : 480px) {
      background-position : -10000px !important;
      display             : flex;
      align-items         : center;
      padding             : 0 0 30px 0;
      justify-content     : center;
      flex-direction      : column;
      flex-wrap           : nowrap;
      align-content       : space-around;
      margin-top          : 25px;
    }

    .upcImage {
      display       : block;
      float         : left;
      position      : relative;
      max-width     : 145px;
      max-height    : 145px;
      margin-bottom : 10px;
      @media only screen and (max-width : 480px) {
        display : none;
      }
    }

    .page-toy-line-section {
      @media only screen and (max-width : 480px) {
        width : 100%;
      }
    }

    .page-size {
      @media only screen and (max-width : 480px) {
        width         : 30%;
        padding-left  : 0;
        padding-right : 0;
      }
    }

    .toy-line {
      @media only screen and (max-width : 480px) {
        width         : 70%;
        padding-right : 0;
      }
    }

    .g-2 {

      @media only screen and (max-width : 480px) {
        --bs-gutter-x : unset;
      }

    }

    .selectToySection {
      display : flex;
      @media only screen and (max-width : 480px) {
        display : block;
      }
    }
  }

}

.form-floating {
  width   : 100%;
  display : flex;
}

.form-floating > label {
  @media only screen and (max-width : 480px) {
    font-size : 12.9px;
  }
  @media only screen and (max-width : 768px) {
    font-size : 12.9px;
  }
}

.loadingSpinner {
  background-color : #FFDCB8;
  text-align       : center;
  min-height       : 360px;
  line-height      : 350px;
}

.table-responsive {
  border : 2px solid #EFEFEF;
  margin : 10px 0;

  table {
    margin-bottom : 0;
  }
}

#product-list-table {
  tbody {
    tr {
      td {
        font-size      : 12.9px;
        vertical-align : middle;
        font-weight    : 500;

        pre {
          margin           : auto;
          background-color : #DAFDEF;
          padding          : 4px 5px 2px 5px;
          line-height      : 20px;
          text-align       : center;
          border-radius    : 3px;
        }
      }
    }
  }
}

.loadingOverlay {
  width            : 100%;
  position         : fixed;
  z-index          : 999;
  text-align       : center;
  background-color : rgba(255, 255, 255, 0.4);
  top              : 0;
  height           : 100%;
  left             : 0;

  img {
    margin   : 0 auto;
    position : absolute;
    top      : 50%;
  }
}

.no-result {
  font-size   : 30px;
  font-weight : 700;
  text-align  : center;
  padding     : 20px 0;
}

.search-button {
  position : absolute;
  top      : 8px;
  right    : 8px;
}

.btn-dark {
  background-color : var(--primary-black) !important;
  border-color     : var(--primary-black) !important;
}

.btn-primary-green {
  background-color : var(--ToysGreen) !important;
  border-color     : var(--ToysGreen) !important;
  padding          : 6px 13px !important;
  color            : #FFFFFF !important;
  font-size        : 17px !important;

  &:hover {
    background-color : #0E754C !important;
    border-color     : #0E754C !important;
  }
}

.page-item.active .page-link {
  background-color : var(--ToysGreen) !important;
  border-color     : var(--ToysGreen) !important;
  color            : #FFFFFF !important;
  font-weight      : 700;
}

.page-link {
  color : var(--ToysGreen) !important;
}

.page-title {
  text-align : center;
  padding    : 20px;
}

.submit-quote-button {
  margin-top : 14px !important;
  @media only screen and (max-width : 480px) {
    position    : fixed;
    width       : 178px;
    content     : 'Submit';
    font-size   : 14px !important;
    text-align  : center !important;
    font-weight : 700 !important;
    bottom      : 12px;
    padding     : 9px 10px !important;
    right       : 12px;
    z-index     : 999;
  }
}

button.scrollTop {
  position         : fixed;
  bottom           : 10px;
  left             : 10px;
  background-color : var(--ToysGreen);
  padding          : 5px 15px;
  color            : #FFFFFF;
  text-decoration  : none;
  font-size        : 14px;
  border           : 1px solid var(--ToysGreen);
  visibility       : hidden;
  cursor           : pointer;

  &.show {
    visibility : visible;
  }
}

.imagePreviews {
  img {
    width  : 80px;
    height : 80px;
    border : 1px solid var(--ToysGreen);
    margin : 5px 5px 5px 0;
  }
}

.imageUploadSection {
  h3 {
    font-size : 15px;
  }
}

button.addDetails {
  display : none;

  i {
    font-size : 15px;
    float     : left;
  }

  &.show {
    display   : block;
    font-size : 11px;
    margin    : 5px auto 5px;
    width     : 94%;
    position  : relative;
    @media only screen and (max-width : 480px) {

      margin : 25px auto 0px auto;
      width  : 140%;
      left   : -27px;
    }
  }
}

button.updateDetails {
  display : none;

  i {
    font-size : 15px;
    float     : left;
  }

  &.show {
    display   : block;
    font-size : 11px;
    margin    : 5px auto 5px;
    width     : 116px;
    position  : relative;
    @media only screen and (max-width : 480px) {
      margin : 25px auto 0px auto;
      width  : 158%;
      left   : -40px;
    }
  }
}

.add-details-modal {
  h2 {
    margin     : 0 0 20px 0;
    text-align : center;
  }
}

.submit-quote-modal {
  h2 {
    margin     : 0 0 20px 0;
    text-align : center;
  }

  label {
    font-size   : 14px;
    font-weight : 500;
    span {
      font-size: 11px;
      color: #cc0000;
    }
  }
}

.quote-summary {
  max-height : 500px;
  overflow-x : scroll;

  table {
    tbody {
      max-height : 500px;
      overflow-x : scroll
    }

    th {
      padding   : 10px 10px 10px 10px;
      font-size : 12px;
    }

    td {
      padding        : 10px 10px 10px 10px;
      vertical-align : middle;

      img {
        width  : 100%;
        border : 1px solid #EBEBEB;
      }

      span {
        font-weight : 500;
        font-size   : 12px;
      }
    }
  }
}

.table-responsive table {

}

.addCustomToyContainer {
  display    : block;
  margin     : 10px 0;
  text-align : right;

  .customProductBtn {
    i {
      float        : left;
      font-size    : 20px;
      margin-right : 5px;
    }
  }
}

.col-md-6 {
  > .form-floating {
    > textarea#quote-note {
      min-height : 280px;
    }
  }
}

textarea#quantity {
  resize : none;
}

.btn-edit {
  background-color : #C9D727;
  padding          : 2px 5px;
  font-size        : 12.5px;
  border-radius    : 180px;
  border           : none;
}

.btn-delete {
  background-color : #D72727 !important;
  padding          : 2px 5px;
  font-size        : 12.5px;
  border-radius    : 180px;
  border           : none;
  color            : #FFFFFF;
}

.quantity-input-group {
  margin : 0 auto;
  width  : 65% !important;
  @media only screen and (max-width : 480px) {
    width : 100% !important;
  }
}

.logo {
  h1 {
    line-height : 57px;
    font-weight : 700;
    @media only screen and (max-width : 768px) {
      font-size : 23px;
    }
    @media only screen and (min-width : 769px) and (max-width : 1280px) {
      font-size : 30px;
    }
    @media only screen and (min-width : 1281px) and (max-width : 1440px) {
      font-size : 35px;
    }
  }
}

body {
  &.fixed {
    main {
      padding-top : 100px;
    }
  }

  header {
    transition : 0.4s cubic-bezier(.38, .77, .95, .95);

    &.fixed {
      position         : fixed;
      z-index          : 999;
      background-color : #FFFFFF;
      box-shadow       : 0px -110px 130px 60px #000000;
    }
  }
}

iframe {
  display : none !important;
}

td, th {
  @media screen and (max-width : 768px) {
    width : 20ch !important;;
  }
}

.hide-mobile {
  @media screen and (max-width : 768px) {
    display : none;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance : none;
}

.search-by {
  display         : flex;
  margin-bottom   : -29px;
  margin-top      : 4px;
  padding-bottom  : 4px;
  flex-direction  : row;
  align-content   : center;
  justify-content : flex-start;
  align-items     : center;
  @media only screen and (max-width : 820px) {
    flex-wrap       : wrap;
    align-items     : flex-start;
    justify-content : center;
  }

  label {
    margin-right : 10px;
    color        : #FFFFFF;
    text-shadow  : 0 1px 1px #000000;

    @media only screen and (max-width : 820px) {
      &:first-child {
        flex : 0 1 100%;
      }
      flex       : 0 1 47%;
      text-align : left;
      margin-top : 3px;
    }

  }
}

strong.error-upc {
  color : #FF0000
}
