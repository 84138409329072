.react-responsive-modal-root {
  position : fixed;
  top      : 0;
  bottom   : 0;
  left     : 0;
  right    : 0;
  z-index  : 1000;
}

.react-responsive-modal-overlay {
  background : rgba(0, 0, 0, 0.5);
  position   : fixed;
  top        : 0;
  bottom     : 0;
  left       : 0;
  right      : 0;
  z-index    : -1;
}

.react-responsive-modal-container {
  height     : 100%;
  outline    : 0;
  overflow-x : hidden;
  overflow-y : auto;
  text-align : center;
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width          : 0;
  height         : 100%;
  content        : '';
  display        : inline-block;
  vertical-align : middle;
}

.react-responsive-modal-modal {
  max-width      : 1000px;
  display        : inline-block;
  text-align     : left;
  vertical-align : middle;
  background     : #FFFFFF;
  box-shadow     : 0 12px 15px 0 rgba(0, 0, 0, 0.25);
  margin         : 1.2rem;
  padding        : 25px 10px;
  position       : relative;
  overflow-y     : auto;
  width          : 90%;
  border         : 9px solid #F5E8D8;
  box-sizing     : content-box;

  @media only screen and (max-width : 480px) {
    margin: 15px 10px;
    padding: 25px 10px;
    position: relative;
    overflow-y: auto;
    width: 85%;
  }
}

.react-responsive-modal-closeButton {
  position         : absolute;
  top              : 0;
  right            : 0;
  border           : none;
  padding          : 0;
  cursor           : pointer;
  background-color : transparent;
  display          : flex;
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity : 1;
  }
  100% {
    opacity : 0;
  }
}

@keyframes react-responsive-modal-modal-in {
  0% {
    transform : scale(0.96);
    opacity   : 0;
  }
  100% {
    transform : scale(100%);
    opacity   : 1;
  }
}

@keyframes react-responsive-modal-modal-out {
  0% {
    transform : scale(100%);
    opacity   : 1;
  }
  100% {
    transform : scale(0.96);
    opacity   : 0;
  }
}
